import React from "react";
import Router from "./routes";
import { AppProvider } from "./provider";
import { initApp } from "./initApp";


initApp();

export const App: React.FC = () => {
  
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );

}