
export function decodeToken<T = Object>(token: string): T | null {
  try {
    if (token.split(".").length !== 3 || typeof token !== "string") {
      return null;
    }

    const payload: string = token.split(".")[1];

    const padding: string = "=".repeat((4 - (payload.length % 4)) % 4);

    const base64: string = payload.replace("-", "+").replace("_", "/") + padding;
    //
    // const jsonPayload = decodeURIComponent(
    //   window.atob(base64)
    //       .split('')
    //       .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    //       .join('')
    // );

    const decoded = JSON.parse(window.atob(base64));

    return decoded;
  } catch (error) {
    return null;
  }
}


export function isTokenExpired(token: string): boolean {
  const decodedToken: any = decodeToken(token);
  let result: boolean = true;
  
  if (decodedToken && decodedToken.exp) {
    const expirationDate: Date = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    result = expirationDate.valueOf() < new Date().valueOf();
  }

  return result;
}
