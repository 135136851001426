import { IntlProvider } from "react-intl";
import { createContext, useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProviderProps } from "@mui/lab";
import { useQueryClient } from "./react-query";
import { Locale, defaultLocale, loadDayjsLocale, loadDatePickerLocale } from "@kemtai/locale";
import audio from "@kemtai/audio";
import { useStorage } from "hooks/useStorage";


export interface LocaleContextType {
  locale: Locale;
  direction: "ltr"|"rtl";
  setLocale: (locale: Locale) => void;
}

export const LocaleContext = createContext<LocaleContextType>({
  locale: defaultLocale,
  direction: "ltr",
  setLocale: () => undefined,
});

export const LocaleProvider: React.FC = ({ children }) => {
  
  const { value: locale, setValue: setLocale } = useStorage<Locale>('locale', defaultLocale);

  const [datePickerLocaleText, setDatePickerLocaleText] = useState<LocalizationProviderProps['localeText']|undefined>(undefined);

  const queryClient = useQueryClient();
  
  const direction = ["he"].includes(locale) ? "rtl" : "ltr";

  const [messages, setMessages] = useState(undefined);

  useEffect(() => {
    async function changeLocale() {
      try {
        const response = await fetch(`/locale/${locale}.json`);
        const messages = await response.json();
        setMessages(messages);
      } catch (e) {
        setMessages(undefined);
      }
      
      await loadDayjsLocale(locale);
      dayjs.locale(locale);

      const datePicketLocaleText = await loadDatePickerLocale(locale);
      setDatePickerLocaleText(datePicketLocaleText);

      audio.setLang(locale);

      queryClient.invalidateQueries();
    }

    changeLocale();
  }, [locale]);

  return (
    <IntlProvider
      messages={messages} 
      locale={locale}
      defaultLocale={defaultLocale}
    >
      <LocaleContext.Provider
        value={{
          locale,
          direction,
          setLocale,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale}
          localeText={datePickerLocaleText}
        >
          {children}
        </LocalizationProvider>
      </LocaleContext.Provider>
    </IntlProvider>
  );

}

export const useLocale = () => {
  const ctx = useContext(LocaleContext);

  return ctx;
};
