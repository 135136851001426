

class Config {
    audioUrlPrefix = 'https://app.kemtai.com/audio'
    mediaUrlPrefix = 'https://app.kemtai.com/media'

    init(config:any){
        if (config.audioUrlPrefix) {
            this.audioUrlPrefix = config.audioUrlPrefix
        }
        if (config.mediaUrlPrefix) {
            this.mediaUrlPrefix = config.mediaUrlPrefix
        }

    }

    audioUrl(url: string) {
        return `${this.audioUrlPrefix}/${url}`
    }

    mediaUrl(url: string) {
        if (url.startsWith("http")) {
            return url;
        }
        return `${this.mediaUrlPrefix}/${url}`
    }
}


export default new Config();
