import { GlobalStyles as MUIGlobalStyles, darken, lighten, useTheme } from '@mui/material';
import React from 'react';

const GlobalStyles = () => {
	const theme = useTheme();

	return (
		<MUIGlobalStyles
			styles={{
				html: {
					height: '100%'
				},
				body: {
					margin: 0,
					padding: 0,
					lineHeight: 1,
					height: '100%',
					touchAction: 'auto',
					userSelect: 'auto',
					overflow: 'hidden',
				},
				'#root': {
					height: '100%'
				},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
				
				'*::-webkit-scrollbar': {
					width: '10px',
					[theme.breakpoints.down("md")]: {
						width: '4px'
					}
				},
				'*::-webkit-scrollbar-track': {
					backgroundColor: theme.palette.secondary['50'],
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb': {
					backgroundColor: theme.palette.secondary['200'],
					borderRadius: '10px',
				},
				'*::-webkit-scrollbar-thumb:hover': {
					backgroundColor: theme.palette.secondary['400'],
				}
			}}
		/>
	);
};

export default GlobalStyles;
