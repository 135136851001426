
class Storage {

  PREFIX = 'kemtai_embed';

  getItem(key: string) {
    return JSON.parse(window.sessionStorage.getItem(`${this.PREFIX}_${key}`) as string);
  }

  setItem(key: string, value: any) {
    window.sessionStorage.setItem(`${this.PREFIX}_${key}`, JSON.stringify(value));
    window.dispatchEvent(new Event('storage'));
  }

  clearItem(key: string) {
    window.sessionStorage.removeItem(`${this.PREFIX}_${key}`);
  }

  getAccessToken() {
    return this.getItem("accessToken");
  }

  setAccessToken(token: string) {
    this.setItem("accessToken", token);
  }

  clearAccessToken() {
    this.clearItem("accessToken");
  }

  getRefreshToken() {
    return this.getItem("refreshToken");
  }

  setRefreshToken(token: string) {
    this.setItem("refreshToken", token);
  }

  clearRefreshToken() {
    this.clearItem("refreshToken");
  }

  clearOnLogout() {
    this.clearAccessToken();
    this.clearRefreshToken();
  }


}

export const storage = new Storage();
