import { User, replayIntegration } from '@sentry/browser';
import { lazyImport } from 'utils/lazyImport';
import { ENVIRONMENT, SENTRY_DSN, VERSION } from '../config';

export const { ErrorBoundary } = lazyImport(() => import('@sentry/react'), 'ErrorBoundary');

export const initSentry = () => {
  if (["production", "qa", "sandbox", "staging"].includes(ENVIRONMENT) && !process.env.CI) {
    import("@sentry/react").then(sentry => {
      sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        release: VERSION,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/[a-z]+-api.kemtai.com/, /^https:\/\/api.kemtai.com/],
        integrations: [
          replayIntegration({
            maskAllText: false,
            networkDetailAllowUrls: [
              window.location.origin,
              /^https:\/\/[a-z]+-api.kemtai.com/, 
              /^https:\/\/api.kemtai.com/,
            ],
            blockAllMedia: false,
          })
        ],
      });
    });
  }
}

export const setSentryUser = (data: User) => {
  import("@sentry/react").then(sentry => {
    sentry.setUser(data);
  });
}