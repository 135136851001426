import { useCallback, useEffect, useState } from 'react';

import { storage } from 'libs/storage';

export const useStorage = <T>(key: string, initialValue: T) => {
	const [value, _setValue] = useState<T>(() => storage.getItem(key) || initialValue);

	const setValue = (newValue: T) => {
		_setValue(newValue);
		storage.setItem(key, newValue);
	};

	return {
		value,
		setValue,
	};
};
