import React, { useContext, useEffect, useRef } from 'react';
import LoggingService from '@kemtai/logger';
import { ENVIRONMENT } from 'config';
import { GPU, device } from "@kemtai/utils";


type User = {
  id: string;
  email?: string;
};

type LogOptions = {
  event: string;
} & Record<string, any>;

type LoggingContextValue = {
  log: (options: LogOptions) => void;
  identify: (user: User) => void;
};

export const LoggingContext = React.createContext<LoggingContextValue>({} as LoggingContextValue);

type LoggingProviderProps = {
  apiUrl?: string;
};

export const LoggingProvider: React.FC<LoggingProviderProps> = ({ apiUrl, children }) => {

  useEffect(() => {
    if (ENVIRONMENT === "production") {
      LoggingService.init({
        app: "wo-app",
        url: apiUrl ?? "https://api.kemtai.com/log/"
      });

      LoggingService.event('Source', {
        referrer: document.referrer,
        url: window.location.href,
        user_agent: navigator.userAgent,
        gpu: GPU(),
        screenSize: `${window.screen.height}x${window.screen.width}`
      });
      LoggingService.event('Device', { type: device.type });
      LoggingService.event('GPU', { name: GPU() })
    }
  }, []);

  const log = ({ event, ...data }: LogOptions) => {
    LoggingService.event(event, data);
  }

  const identify = (user: User) => {
    LoggingService.setContext("user_email", user.id);

    log({
     event: "user",
     data: {
       id: user.id,
       email: user.email
     }
    });
  }

	return (
		<LoggingContext.Provider value={{ log, identify }}>
		  {children}
		</LoggingContext.Provider>
	);
};


export const useLog = () => {
	const ctx = useContext(LoggingContext);

	return ctx;
};
