import { defaultLocale } from '@kemtai/locale';
import Axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import { storage } from 'libs/storage';
import { applyAuthInterceptor } from "@kemtai/api-client";


export const axios = Axios.create({
  baseURL: API_URL,
});

async function localizationRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
  const locale = storage.getItem("locale");

  config.headers!['Accept-Language'] = locale ?? defaultLocale;

  return config;
}

async function organizationRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
  const organizationId = storage.getItem("organizationId");

  if (organizationId && (config.url?.startsWith("care/") || config.url?.startsWith("/care/"))) {
    config.headers!['X-Organization'] = organizationId;
  }

  return config;
}

axios.interceptors.request.use(localizationRequestInterceptor);
axios.interceptors.request.use(organizationRequestInterceptor);

const refreshEmbeddableToken = () => {
  return new Promise<{ access: string }>((resolve, reject) => {
    window.parent.postMessage({
      type: "token_expired"
    }, "*"); 

    const handleMessage = (message: MessageEvent) => {
      if (message.data.type === "token") {
        resolve({
          access: message.data.token
        });

        window.removeEventListener("message", handleMessage);
      }
    }

    window.addEventListener("message", handleMessage);
  });
}

applyAuthInterceptor(
  axios,
  {
    requestRefresh: refreshEmbeddableToken,
    getStorage: () => storage,
    useRefreshToken: false
  }
);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
);