import { Howl, HowlOptions, Howler } from 'howler';
import Config from './config';
import { TSoundsSettings, defaultSoundsSettings } from '@kemtai/interfaces';

const DEFAULT_LANG = "en-US";

class AudioController {
  sound: Howl | null = null;
  soundtrack: Howl | null = null;
  soundEffect: Howl | null = null;
  currentSoundtrackId: number | null = null;
  soundShouldResumeOnPause: boolean = false;
  isMuted: boolean = false;
  Volume: TSoundsSettings = defaultSoundsSettings;

  gender: string = "female";
  lang: string = DEFAULT_LANG;

  init(config: any, lang: string = DEFAULT_LANG) {
    Config.init(config);
    this.lang = lang;
  }

  setGender(gender: string) {
    this.gender = gender === "M" ? "male" : "female";
  }

  setLang(lang: string) {
    this.lang = lang;
  }

  setVolume(Volume: TSoundsSettings) {
    this.Volume = Volume;
  }

  get speechVolume(): number {
    return this.Volume.speech_on ? this.Volume.speech_volume / 100 : 0;
  }

  get soundEffectsVolume(): number {
    return this.Volume.soundeffects_on ? this.Volume.soundeffects_volume / 100 : 0;
  }

  get soundTrackVolume(): number {
    return this.Volume.soundtrack_on ? this.Volume.soundtrack_volume / 100 : 0;
  }

  get fadeVolume() {
    return 0.02;
  }

  muteAll() {
    Howler.mute(true);
    this.isMuted =true;
  }

  unmuteAll() {
    Howler.mute(false);
    this.isMuted =false;

  }

  resetAudioContext() {
    if (Howler.ctx && Howler.ctx.state === 'suspended') {
      Howler.ctx.resume().then(() => {
        this.unmuteAll();
      });
    }
  }

  playSound(label: string, options: any = {}) {
    this.soundShouldResumeOnPause = options.shouldResumeOnPause ?? false;
  
    if (options && options.async !== true) {
        if (this.sound !== null) {
            this.sound.stop();
        }
    }
  
    const filePath = this.generateFilePath(label, options.gender, options.useFallbackLang);
    const fallbackFilePath = this.generateFilePath(label, options.gender, true);
    const delay = options.delay ? options.delay * 1000 : 0;
    const volume = options.volume ?? this.speechVolume;
  
    if (filePath === "") {
      if (options.callback) {
        options.callback();
      }
      return;
    }
  
    const fade = this.soundtrack && this.currentSoundtrackId &&
      this.soundTrackVolume > this.fadeVolume &&
      this.speechVolume > 0;
  
   const playHowl = (path: string, callback: any) => {
      this.sound = new Howl({
        src: [path],
        volume: volume,
        onplay: (sound_id) => {
          if (fade && this.soundtrack) {
            this.soundtrack.fade(this.soundTrackVolume, this.fadeVolume, 500, this.currentSoundtrackId!);
          }
        },
        onend: (sound_id) => {
          if (fade && this.soundtrack) {
            this.soundtrack.fade(this.fadeVolume, this.soundTrackVolume, 2000, this.currentSoundtrackId!);
          }
            },
            onloaderror: () => {
                if (path !== fallbackFilePath) {
                    playHowl(fallbackFilePath, callback);
                } else {
                    if (callback) {
                        callback();
                    }
          }
        },
      });
  
      this.sound.on('end', () => {
        if (callback) {
          this.sound = null;
          callback();
        }
      });
      setTimeout(() => {
        if (this.sound) {
          this.sound.play();
        }
      }, delay);
    };
  
    playHowl(filePath, options.callback);
    return this.sound;
  }


  howl(label: string, options: Omit<HowlOptions, 'src'> = {}) {
    const filePath = this.generateFilePath(label);
    return new Howl({
      src: [filePath],
      volume: this.speechVolume,
      ...options
    });
  }

  pauseSound() {
    if (this.sound !== null) {
      this.sound.pause();
    }
  }

  resumeSound() {
    if (this.sound !== null && this.soundShouldResumeOnPause) {
      this.sound.play();
    }
  }

  stopSound() {
    if (this.sound !== null) {
      this.sound.stop();
      this.sound = null;
    }
  }

  startSoundtrack(soundtrack: string | undefined | null) {
    if (soundtrack && this.soundTrackVolume > 0) {
      this.playSoundtrack(Config.mediaUrl(soundtrack));
    }
  }

  playSoundtrack(soundtrack: string, options: any = {}): void {
    if (this.soundtrack !== null) {
      return;
    }

    if (!soundtrack) {
      soundtrack = Config.audioUrl("soundtracks/main.mp3");
    }

    const delay = options.delay ? options.delay * 1000 : 0;
    const format = options.format ? options.format : [];
    const volume = options.volume ?? this.soundTrackVolume;
    this.soundtrack = new Howl({
      src: [soundtrack],
      loop: true,
      format: format,
      volume: volume,
    });

    if (this.soundtrack.state() === "loaded") {
      if (this.soundtrack) {
        this.currentSoundtrackId = this.soundtrack.play();
      }
    } else {
      this.soundtrack.once('load', () => {
        if (this.soundtrack) {
          this.currentSoundtrackId = this.soundtrack.play();
        }
      });
    }
  }

  stopSoundtrack() {
    if (this.soundtrack !== null) {
      this.soundtrack.stop();
      this.soundtrack = null;
    }
  }

  pauseSoundtrack() {
    if (this.soundtrack !== null) {
      this.soundtrack.pause();
    }
  }

  resumeSoundtrack() {
    if (this.soundtrack !== null) {
      this.currentSoundtrackId = this.soundtrack.play();
    }
  }

  playSoundEffect(label: string, options: any = {}) {
    const volume = options.volume ?? this.soundEffectsVolume;

    this.soundEffect = new Howl({
      src: [Config.audioUrl(`sound_effects/${label}.mp3`)],
      volume: volume,
    });

    this.soundEffect.play();
  }

  pauseSoundEffect() {
    if (this.soundEffect !== null) {
      this.soundEffect.pause();
    }
  }

  resumeSoundEffect() {
    if (this.soundEffect !== null) {
      this.soundEffect.play();
    }
  }

  stopSoundEffect() {
    if (this.soundEffect) {
      this.soundEffect.stop();
      this.soundEffect = null;
    }
  }

  stopAll() {
    this.stopSound();
    this.stopSoundEffect();
    this.stopSoundtrack();
  }

  pauseAll() {
    this.pauseSound();
    this.pauseSoundEffect();
    this.pauseSoundtrack();
  }

  resumeAll() {
    this.resumeSound();
    this.resumeSoundEffect();
    this.resumeSoundtrack();
  }

  setSoundtrackVolume(volume: number, mute?: boolean) {
    if (typeof mute !== "undefined") {
      this.Volume.soundtrack_on = mute;
    }
    this.Volume.soundtrack_volume = volume;
    this.soundtrack?.volume(this.soundTrackVolume);
  }

  djb_hash(message: string) {
    const msgUint8 = new TextEncoder().encode(message);
    let result = 5381 >>> 0;
    for (let i = 0; i < msgUint8.length; i++) {
      result = (((((result << 5) >>> 0) + result) >>> 0) + msgUint8[i]) >>> 0;
    }
    return result;
  }

  generateFilePath(label: string, gender: string = this.gender, useFallbackLang: boolean = false): string {
    if (label.startsWith("data:audio")) {
      return label;
    }

    return Config.audioUrl(`${useFallbackLang ? DEFAULT_LANG : this.lang}/${gender}/${encodeURIComponent(label.replace(/\//g, "_"))}.mp3`);
  }

  getAudioDuration(label: string, timeout: number = 4000): Promise<number> {
    const timeoutPromise: Promise<number> = new Promise(resolve => setTimeout(() => { resolve(0); }, timeout));
    const durationPromise: Promise<number> = new Promise((resolve, reject) => {
      const filePath = this.generateFilePath(label);

      const sound = new Howl({
        src: [filePath],
      });

      if (sound.duration()) {
        resolve(sound.duration());
      }

      sound.once('load', () => {
        resolve(sound.duration());
      });

      sound.once('loaderror', () => {
        resolve(0);
      });
    });
    return Promise.race([durationPromise, timeoutPromise]);
  }
}

const Audio = new AudioController();
export default Audio;
