import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import minMax from 'dayjs/plugin/minMax';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import { initSentry } from 'libs/sentry';


export const initApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  
  if (urlParams.get("kemtai_record")) {
    window.__KEMTAI_RECORD__ = true;
  }

  initSentry();
  
  dayjs.extend(weekOfYear);
  dayjs.extend(minMax);
  dayjs.extend(localeData);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(UpdateLocale);
}
