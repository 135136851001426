import React, { useContext, useEffect, useState } from "react";
import { storage } from "./storage";
import { AppearanceOptions, EmbeddableOptionsMap as StandaloneEmbeddableOptionsMap } from "@kemtai/web-sdk";
import { EmbeddableOptionsMap as CareEmbeddableOptionsMap } from "@kemtai/web-care-sdk";
import { useTheme } from "theme";
import { useLocale } from "./locale";
import { Locale } from "@kemtai/locale";
import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { setSentryUser } from "./sentry";
import { useLog } from "./logging";
import { decodeToken } from "utils/jwt";
import { Session } from "types";


type MessagePayload<
  StandaloneEmbeddable extends keyof StandaloneEmbeddableOptionsMap = keyof StandaloneEmbeddableOptionsMap,
  CareEmbeddable extends keyof CareEmbeddableOptionsMap = keyof CareEmbeddableOptionsMap,
> = {
  type: "token";
  token: string;
} | {
  type: "appearance";
  appearance?: AppearanceOptions;
} | {
  type: "locale";
  locale: string;
} | {
  type: "options";
  embeddableName: StandaloneEmbeddable;
  options: {
    runType: "standalone",
  } & StandaloneEmbeddableOptionsMap[StandaloneEmbeddable];
} | {
  type: "options";
  embeddableName: CareEmbeddable;
  options: {
    runType: "care",
  } & CareEmbeddableOptionsMap[CareEmbeddable];
};


type EmbeddablePropsMap<T> = T extends "standalone"
  ? StandaloneEmbeddableOptionsMap
  : CareEmbeddableOptionsMap;

type EmbeddableContextValue = {
  getProps: <
    T extends "standalone" | "care", 
    K extends keyof EmbeddablePropsMap<T>
  >(runType: T, name: K) => EmbeddablePropsMap<T>[K] | undefined;
  session: Session|null;
};

export const EmbeddableContext = React.createContext<EmbeddableContextValue>({} as EmbeddableContextValue);

export const EmbeddableProvider: React.FC = ({ children }) => {

  const [standaloneEmbeddables, setStandaloneEmbeddables] = useState<Partial<StandaloneEmbeddableOptionsMap>>({});
  const [careEmbeddables, setCareEmbeddables] = useState<Partial<CareEmbeddableOptionsMap>>({});

  const { setAppearance } = useTheme();
  const { setLocale } = useLocale();
  const [token, setToken] = useState<string>();
  const [session, setSession] = useState<Session|null>(null);

  const sessionQuery = useQuery<Session>({
    queryFn: () => axios.get("/embed/my-session/"),
    queryKey: ["my-session"]
  });

  const handleMessage = (message: MessageEvent<MessagePayload>) => {
    if (message.data.type === "token") {
      storage.setAccessToken(message.data.token);
      setToken(message.data.token);
      
      const decodedToken = decodeToken(message.data.token) as { owner_id: string };
      storage.setItem("organizationId", decodedToken['owner_id']);
    } else if (message.data.type === "appearance") {
      setAppearance(message.data.appearance);
    } else if (message.data.type === "locale") {
      setLocale(message.data.locale as Locale);
    } else if (message.data.type === "options") {
      if (message.data.options.runType === "standalone") {
        setStandaloneEmbeddables({
          ...standaloneEmbeddables,
          [message.data.embeddableName]: message.data.options,
        });
      } else {
        setCareEmbeddables({
          ...careEmbeddables,
          [message.data.embeddableName]: message.data.options,
        });
      }
    }
  }

  const { identify } = useLog();

  useEffect(() => {
    if (sessionQuery.data) {
      setSession(sessionQuery.data);

      setSentryUser({
        id: sessionQuery.data.extra_data.user_id,
        email: sessionQuery.data.extra_data.email
      });

      identify({
        id: sessionQuery.data.user_id,
        email: sessionQuery.data.extra_data.email
      });
    }
  }, [sessionQuery.data]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    window.parent.postMessage({
      type: "ready"
    }, "*");

    return () => window.removeEventListener('message', handleMessage);;
  }, []);

  const getProps = <T extends "standalone" | "care", K extends keyof EmbeddablePropsMap<T>>(runType: T, name: K) => {
    if (runType === "standalone") {
      return standaloneEmbeddables[name as keyof StandaloneEmbeddableOptionsMap] as EmbeddablePropsMap<T>[K];
    } else {
      return careEmbeddables[name as keyof CareEmbeddableOptionsMap] as EmbeddablePropsMap<T>[K];
    }
  }

  return (
    <EmbeddableContext.Provider 
      value={{ 
        getProps,
        session,
      }}
    >
      {children}
    </EmbeddableContext.Provider>
  );

}

export const useEmbeddable = () => {
  return useContext(EmbeddableContext);
}