import React, { Suspense } from 'react';
import { ConfirmationDialogProvider, Loader } from '@kemtai/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'libs/react-query';
import { EmbeddableProvider } from 'libs/embeddable';
import { ThemeProvider } from 'theme';
import { LocaleProvider } from 'libs/locale';
import { ErrorFallback } from './ErrorFallback';
import { ErrorBoundary } from '@sentry/react';
import { LoggingProvider } from 'libs/logging';


export const AppProvider: React.FC = ({ children }) => {

  return (
    <Suspense fallback={<Loader fullscreen />}>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <ThemeProvider>
            <ErrorBoundary
              fallback={
                ({ eventId }) => <ErrorFallback eventId={eventId} />
              }
            >
              <LoggingProvider>
                <ConfirmationDialogProvider>
                  <EmbeddableProvider>
                    {children}
                  </EmbeddableProvider>
                </ConfirmationDialogProvider>
              </LoggingProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </LocaleProvider>
      </QueryClientProvider>
    </Suspense>
  );

}
