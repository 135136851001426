import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';


const ROMAssessment = lazy(() => import('features/rom-assessment'));
const Form = lazy(() => import('features/forms'));
const ExerciseProtocol = lazy(() => import('features/exercise-protocol'));
const ExerciseProtocolResult = lazy(() => import('features/exercise-protocol-result'));
const Task = lazy(() => import('features/task'));
const EducationalContent = lazy(() => import('features/educational-content'));
const FunctionalAssessment = lazy(() => import('features/functional-assessment'));
const FunctionalAssessmentResult = lazy(() => import('features/functional-assessment-result'));
const Workout = lazy(() => import('features/exercise-protocol'));
const WorkoutResult = lazy(() => import('features/exercise-protocol-result'));
const STSAssessment = lazy(() => import('features/sts-assessment'));


export const protectedRoutes: RouteObject[] = [
  {
    path: "/rom-assessment/*",
    element: <ROMAssessment />
  },
  {
    path: "/sts-assessment/*",
    element: <STSAssessment />
  },
  {
    path: "/form/*",
    element: <Form />
  },
  {
    path: "/exercise-protocol/*",
    element: <ExerciseProtocol />
  },
  {
    path: "/workout/*",
    element: <Workout />
  },
  {
    path: "/exercise-protocol-result/*",
    element: <ExerciseProtocolResult />
  },
  {
    path: "/workout-result/*",
    element: <WorkoutResult />
  },
  {
    path: "/task/*",
    element: <Task />
  },
  {
    path: "/educational-content/*",
    element: <EducationalContent />
  },
  {
    path: "/functional-assessment/*",
    element: <FunctionalAssessment />
  },
  {
    path: "/functional-assessment-result/*",
    element: <FunctionalAssessmentResult />
  },
  {
    path: "/error",
    element: <div></div>
  },
  {
    path: "*",
    element: <Navigate to="/error" />
  }
];


const Router = () => {
  const protectedElement = useRoutes(protectedRoutes) as JSX.Element;

	return protectedElement;
};

export default Router;
