import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalStyles from './globalStyles';
import { composeTheme } from './themes';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { AppearanceOptions } from '@kemtai/web-sdk';
import { useLocale } from 'libs/locale';



type ThemeContextValue = {
	appearance: AppearanceOptions|undefined;
	setAppearance: (appearance?: AppearanceOptions) => void;
};

export const ThemeContext = React.createContext<ThemeContextValue>({} as ThemeContextValue);

export const ThemeProvider: React.FC = ({ children }) => {

  const [appearance, setAppearance] = useState<AppearanceOptions>();
  const { direction } = useLocale();

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  const theme = composeTheme({
    direction,
    appearance,
  });

  if (direction === "rtl") {
    const cacheRtl = createCache({
      key: 'muirtl',
      stylisPlugins: [prefixer, rtlPlugin],
    });

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeContext.Provider
          value={{
            appearance,
            setAppearance,
          }}
        >
          <MuiThemeProvider 
            theme={theme}
          >
            <CssBaseline />
            <GlobalStyles />

            {children}
          </MuiThemeProvider>
        </ThemeContext.Provider>
      </CacheProvider>
    );
  } else {
    return (
      <ThemeContext.Provider
        value={{
          appearance,
          setAppearance,
        }}
      >
        <MuiThemeProvider 
          theme={theme}
        >
          <CssBaseline />
          <GlobalStyles />

          {children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }

}

export const useTheme = () => {
	const ctx = useContext(ThemeContext);

	return ctx;
};
